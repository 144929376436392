/*jshint esversion: 6 */
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import bootstrap from 'bootstrap';

$(document).ready(function() {
  "use strict";

  /*+++++++++++++++++++++
   * +++++++ DATUM ++++++
   * ++++++++++++++++++++
   */
  const heute = new Date();
  var year = heute.getFullYear();
  $(".year").text(year);

  /*++++++++++++++++++++++++
   * +++++++ WOW INIT ++++++
   * +++++++++++++++++++++++
   */
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 200,
    mobile: true,
    live: true,
    callback: function(box) {},
    scrollContainer: null,
    resetAnimation: true,
  });
  wow.init();

  /*++++++++++++++++++++++++
   * +++++++ SCROLLUP ++++++
   * +++++++++++++++++++++++
   */
  $('.scrollup').click(function() {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  // Navigation Scroll
  $(window).scroll(function(event) {
    Scroll();

    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
      $("#nav-img").stop().addClass('nav-img-sm', 1000, "easeInOutQuad");
    } else {
      $('.scrollup').fadeOut();
      $("#nav-img").stop().removeClass('nav-img-sm', 1000, "easeInOutQuad");
    }
  });

  $('.navbar-collapse ul li a.start-nav').click(function() {
    $('html, body').animate({
      scrollTop: $(this.hash).offset().top - 79
    }, 1000);
    return false;
  });

  $('.start-link').click(function() {
    $('html, body').animate({
      scrollTop: $(this.hash).offset().top - 79
    }, 1000);
    return false;
  });

  function Scroll() {
    var contentTop = [];
    var contentBottom = [];
    var winTop = $(window).scrollTop();
    var rangeTop = 100;
    var rangeBottom = 500;

    $('.navbar-collapse').find('.scroll a.start-nav').each(function() {
      contentTop.push($($(this).attr('href')).offset().top);
      contentBottom.push($($(this).attr('href')).offset().top + $($(this).attr('href')).height());
    });

    $.each(contentTop, function(i) {
      if (winTop > contentTop[i] - rangeTop) {
        $('.navbar-collapse li.scroll')
          .removeClass('active')
          .eq(i).addClass('active');
      }
    });
  }
}); //document
