/*jshint esversion: 6 */
$(document).ready(function() {
  "use strict";

  /*++++++++++++++++++++++++++++
   * +++++++ TEXTUR MODAL ++++++
   * +++++++++++++++++++++++++++
   */
  $('#TexturModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var recipient = button.data('textur');
    var text_name = button.data('name');
    $("#modalImg").html('<img src="assets/images/texturen/'+recipient+'" class="img-fluid mx-auto d-block rounded">');
    var modal = $(this);
    modal.find('.modal-title').text(text_name);
  });

  /*+++++++++++++++++++++++++++++
   * +++++++ Select Option ++++++
   * ++++++++++++++++++++++++++++
   */


  $(document).on('click', '.selected-btn', function() {
    $("#carouselOptionenIndicators img").stop().removeAttr('style');
    $(this).css("border-color", "var(--green)");
    var id = $(this).attr('data-id');
    $(".select-option").html('<span class="text-success">' + id + '</span>');
    $("#sel-textur").val(id);
  });

  /*+++++++++++++++++++++++++++++++
   * +++++++ Submit Formular ++++++
   * ++++++++++++++++++++++++++++++
   */

  $(document).on('submit', '#send-message', function() {
    var form_data = $(this).serializeObject();

    //ajax formular senden

    if (form_data.sel_textur) {
      success_message('Vielen Dank ' + form_data.vorname + '. Die Nachricht wurde gesendet!');
      $('#send-message').trigger("reset");
      $("#carouselOptionenIndicators img").stop().removeAttr('style');
    } else {
      warning_message('Bitte eine Option auswählen!');
    }
    return false;
  });

  /*+++++++++++++++++++++++++++++++
   * +++++++ Formular Change ++++++
   * ++++++++++++++++++++++++++++++
   */

  $(".holz_color").change(function() {
    var id = $("[name='farb_code']").val();
    $("#collapseFarbCode").collapse('hide');
    if (id == 2) {
      $("#collapseFarbCode").collapse('show');
    }
  });

  $(".change_kabel").change(function() {
    var id = $("[name='kabel']").val();
    $("#collapseKabelCode").collapse('hide');
    if (id == 2) {
      $("#collapseKabelCode").collapse('show');
    }
  });


  /*+++++++++++++++++++++++++
   * +++++++ Serialize ++++++
   * ++++++++++++++++++++++++
   */
  $.fn.serializeObject = function() {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  /*++++++++++++++++++++++++++++++
   * +++++++ Message Handle ++++++
   * +++++++++++++++++++++++++++++
   */
  function success_message(msg) {
    var x = document.getElementById("snackbar-success");
    $("#snackbar-success").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

  function warning_message(msg) {
    var x = document.getElementById("snackbar-warning");
    $("#snackbar-warning").text(msg);
    x.className = "show";
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }

}); //document
